import { Box, Button, LoadingOverlay, Text, UnstyledButton } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { AuthApi } from "../apis";
import { authChange, authMe } from "../store/auth";
import { message } from "../utils/message";
import { Form } from "./form";
import { PasswordField } from "./form/password-field";
import { TextField } from "./form/text-field";

type IForm = {
  code: string | undefined;
  username: string | undefined;
  password: string | undefined;
};

const schema = yup.object({
  code: yup.string().required("Кодоо оруулна уу").trim(),
  username: yup.string().required("Нэвтрэх нэр оруулна уу").trim().lowercase(),
  password: yup.string().required("Нууц үг оруулна уу"),
});

const LoginForm = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  const [data] = React.useState<IForm>({
    code: undefined,
    username: undefined,
    password: undefined,
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      const res = await AuthApi.login(values);
      dispatch(authChange(res));

      const auth = await AuthApi.me();
      dispatch(authMe(auth));

      navigate("/product");
      message.success("Амжилттай нэвтэрлээ");
      setLoading(false);
    } catch (error: any) {
      error?.message && message.error(error?.message);
      setLoading(false);
    }
  };

  return (
    <Box mx="auto" className={classes.container}>
      <LoadingOverlay visible={loading} />
      <Form onSubmit={onSubmit} initialValues={data} validationSchema={schema}>
        {() => {
          return (
            <Box className={classes.formWrapper}>
              <TextField label="Нэвтрэх код оруулна уу:" size="md" variant="unstyled" placeholder="Нэвтрэх код" className={classes.input} name="code" />
              <TextField
                label="Хэрэглэгчийн нэрээ оруулна уу:"
                size="md"
                variant="unstyled"
                placeholder="Хэрэглэгчийн нэр"
                className={classes.input}
                name="username"
              />
              <PasswordField
                styles={{
                  rightSection: {
                    position: "absolute",
                    backgroundColor: "#00000050",
                    borderBottomRightRadius: 4,
                    borderTopRightRadius: 4,
                    height: 40,
                  },
                }}
                size="md"
                label="Нууц үг оруулах:"
                variant="unstyled"
                placeholder="Нууц үг"
                className={classes.input}
                name="password"
              />
              <div className={classes.forgotText}>
                Нууц үг ээ мартсан бол
                <Link to={"/forgot"}>
                  <Text span c="blue" inherit className={classes.highLightText}>
                    ЭНД ДАРЖ
                  </Text>
                </Link>
                сэргээнэ үү.
              </div>
              <Button variant="gradient" gradient={{ from: "red", to: "#051139" }} size="md" w="100%" type="submit">
                Нэвтрэх
              </Button>
              <div className={classes.forgotText}>
                Байгууллага бүртгэлгүй бол
                <UnstyledButton
                  onClick={() => {
                    window.location.href = "https://dev-partner.dehub.mn/register";
                  }}>
                  <Text span c="blue" inherit className={classes.highLightText}>
                    ЭНД ДАРЖ
                  </Text>
                </UnstyledButton>
                бүртгүүлнэ үү.
              </div>
            </Box>
          );
        }}
      </Form>
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    width: "100%",
  },
  input: {
    input: {
      backgroundColor: "#00000050",
      paddingInline: 10,
      borderRadius: theme.radius.sm,

      "&:focus-within": {
        color: theme.white,
      },
      color: theme.white,
    },
    label: {
      color: theme.white,
      marginBottom: 10,
    },
    color: theme.white,
    alignItems: "center",
  },
  forgotText: {
    color: "white",
    textAlign: "center",
  },
  highLightText: {
    fontWeight: 700,
    marginLeft: 10,
    marginRight: 10,
  },
  formWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    marginTop: 20,
  },
}));

export default LoginForm;
